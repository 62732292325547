
body {
    background-color: black;
    color: #cccccc;
    font-family: 'Overpass', sans-serif;
}
.app {
    text-align: center;
}
.container {
    max-width: 740px;
    margin:0 auto;
    padding-top: 2.4rem;
    padding-bottom: 10rem;
}
.logo {
    margin: 0 auto 3rem auto;
    color: #FFFFFF;
}
.logo > h1 {
    font-size: 2.8rem;
    line-height: 3.4rem;
    font-weight: 500;
}
.logo > h1 > strong {
    font-weight: 700;
}
.logo > h4 {
    display: inline-block;
    font-size: .9rem;
    line-height: 1rem;
    font-weight: 300;
    color: #000000;
    background-color: #26CC86;
    padding: .6rem 1rem .4rem 1rem;
    border-radius: 4px;
}

footer {
    display: block;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #FFFFFF;
    text-align: center;
    padding: 1rem 0;
}
footer .built-with {
    display: inline-flex;
    margin:0 auto;
}
footer .built-with span {
    margin-right: .5rem;
    align-self: center;
    color: #212121;
}
footer .built-with a {
    display: inline-block;
}
footer .built-with a img {
    height: 24px;
}

.domains {
    text-align: left;
    margin-bottom: 3rem;
}
.domain {
    border: 1px solid #1f1f1f;
    border-radius: .4rem;
    padding: 1.4rem;
    background: rgb(255,255,255);
    background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.1) 100%);
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
}

.domain > div:first-child > strong {
    font-size: 1.5rem;
    font-weight: 500;
    color: #32FFA9;
    display: block;
    margin-bottom: .3rem;
}
.domain > div:first-child > strong > span {
    color: #cccccc;
}
.domain > div:first-child > span {
    display: block;
    font-size: .9rem;
    font-weight: 300;
    color: #8C8C8C;
    margin-bottom: .2rem;
}

.domain > div:last-child {
    text-align: right;
}
.domain > div:last-child > * {
    display: block;
    font-size: .9rem;
    color: #8C8C8C;
}
.domain > div:last-child strong {
    font-size: 1.2rem;
    font-weight: 500;
    color: #FFFFFF;
    margin-bottom: .3rem;
}

.domain > div {
    align-self: center;
}


.pending-bar {
    margin-bottom: 1.6rem;
    display: block;
    background-color: #181818;
    font-size: .9rem;
    font-weight: 200;
    color: #8C8C8C;
    padding: .7rem .6rem .6rem;
    border-radius: 4px;
    position: relative;
}

.bar-waiting {
    overflow: hidden;
    text-align: left;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}

.bar-anim {
    margin: 0 auto;
    height: 1px;
    width: 0;
    background-color: #525252;
    -webkit-animation: 3s new-pending linear infinite;
    -moz-animation: 3s new-pending linear infinite;
    animation: 3s new-pending linear infinite;
}
@keyframes new-pending {
    0%   { width: 0}
    50%   { width: 100%}
    100% { width: 0}
}
@-webkit-keyframes new-pending {
    0% {width: 0}
    50%   { width: 100%}
    100% { width: 0}
}

.how-to {
    text-align: left;
    font-size: 1rem;
    color: #b6b6b6;
    font-weight: 200;
}
.mb-2 {
    margin-bottom: 1rem;
}

.how-to h2 {
    margin-bottom: 1rem;
    color: #FFFFFF;
}
.how-to-list {
    margin-bottom: 2rem;
    list-style: outside numeric;
    margin-left: 2rem;
}
.how-to-list li {
    margin-bottom: 1.2rem;
    display: list-item;
}
.how-to-list li > strong {
    color: #FFFFFF;
    display: block;
    margin-bottom: .5rem;
}
.how-to-list li > p {
    display: block;
    margin-bottom: .3rem;
}
.how-to-action {
    color: #cccccc;
    margin-left: 1rem;
}
.how-to-action h3 {
    color: #FFFFFF;
    display: block;
    margin-bottom: 1rem;
}
.btn-get-started {
    display: inline-block;
    border: 0;
    padding: .6rem 2rem .5rem 2rem;
    font-size: 1.4rem;
    background-color: rgb(242, 174, 42);
    color: #000000;
    font-weight: 700;
    text-decoration: none;
    border-radius: 23px;
}
